<template>
  <section class="login-block gradient">

    <div class="container mb-4">
      <div class="row">
        <div class="col-12 py-5">
<!--
          <h1 class="text-light">PrinterApi</h1>
          <p class="text-light" style="font-size: 1.4em;">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi porro qui perferendis quos quis fugit vitae ipsam provident voluptatibus modi deserunt, reprehenderit explicabo quia illum. Itaque unde minima voluptas voluptates!</p>
-->
        </div>
      </div>
    </div>

    <div class="container login-container">
      <div class="row">
        
        <div class="col-md-4 login-side position-relative px-5 px-md-3 px-lg-4 px-xl-5 py-4">
          <h3 class="text-center text-dark mb-4">Login</h3>
          
          <form class="login-form" action="javascript:void(0);" method="get">
            <div class="form-group">
              <label class="text-uppercase">Username</label>
              <input ref="username" type="text" v-model="login.user" class="form-control" placeholder="" @keyup.enter="authenticate">
            </div>
            <div class="form-group">
              <label class="text-uppercase">Password</label>
              <input type="password" v-model="login.password" class="form-control" placeholder="" @keyup.enter="authenticate">
            </div>
          </form>

          <button type="button" class="btn btn-login font-weight-bold text-white login-btn float-right mt-1" @click="authenticate">Submit</button>
          <div class="copy-text">PrinterAPI <b-icon icon="heart" class="text-danger"></b-icon> Alfredssons</div>
        </div>

        <div class="col-md-8 carousel-side">

          <b-carousel
            id="login-carousel"
            fade
            controls
            indicators
            v-model="slide"
            class="d-none d-md-block h-100"
          >
            <b-carousel-slide
              caption=""
              text=""
              class="h-100"
            >
              <template #img>
                <div class="d-block w-100 h-100 image image-1"></div>
              </template>
            </b-carousel-slide>

            <b-carousel-slide
              caption=""
              text=""
              class="h-100"
            >
              <template #img>
                <div class="d-block w-100 h-100 image image-2"></div>
              </template>
            </b-carousel-slide>

          </b-carousel>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      login: {
        user: "",
        password: "",
        production_unit: "alfredssons",
      },
      slide: 0,
    };
  },

  methods: {
    authenticate() {
      //this.$toastIt(this.$store.state.auth_base_url, { variant: "info", title: "Debug" });
      //console.log(this.login);
      this.$http
      .post(this.$store.state.auth_base_url + "/auth", this.login)
      .then((result) => {
        if (result.data.status == "ok") {
          // Store token for this session
          this.$store.state.token = result.data.token;
          // Persistent session :)
          window.sessionStorage.setItem("token", result.data.token);
          // Load initial data once logged in. MOVED HERE - Also in Vue init (if we have a token there, reloads n stuff)
          this.$store.dispatch('fetchInitialData');
          // Tickets (test to split it up)
          this.$store.dispatch('ticket/fetchActiveTickets', {});
          // Send user to the inside
          this.$router.push({ name: "Dashboard" });
        }
        if (result.data.status != "ok") {
          const msg = result.data.message ?? "Login or password incorrect.";
          this.$toastIt(msg, { variant: "danger", title: "Access denied" });
        }
      })
      .catch((error) => {
        //const msg = error?.response ?? error?.request ?? error?.message ?? "Unknown error, please try again later.";
        const msg = error?.message ?? "Unknown error, please try again later.";
        this.$toastIt(msg, { variant: "danger", title: "Error" });
      });
    },
  },

  // Force logout by removing token as soon as we land on this page
  mounted() {
    this.$store.state.token = null;
    window.sessionStorage.removeItem("token");
    
    const username = this.$refs.username;
    username.focus();
  },
};
</script>

<style lang="scss">
.login-block{
  float:left;
  width:100%;
  padding : 30px 0 50px 0;
  height: 100vh;

  .login-container { 
    background:#fff;
    border-radius: 10px;
    box-shadow:15px 20px 0px rgba(0,0,0,0.1);
  }
  
  h3:after{
    content: " ";
    width: 100px;
    height: 5px;
    background:#393949;
    display: block;
    margin-top: 10px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto
  }
  .copy-text{
    position:absolute;
    left: 0;
    width:100%;
    bottom:20px;
    font-size:13px;
    text-align:center;
  }

  .login-side { min-height: 500px; }

  .carousel-side{ border-radius: 0 10px 10px 0; padding:0; overflow: hidden; }
  .carousel-item { height: 100%; }
  .carousel-inner{ height: 100%; }

  .carousel-caption {
    right: 10%;
    left: 10%;
    text-align: left;
  }

  .carousel-inner .image {
    background-size: cover;
    background-position: center center;

    &.image-1 {
      background-image: url("https://unsplash.com/photos/lSm3dnkFRdY/download?force=true&w=640");
//      "https://images.pexels.com/photos/5641937/pexels-photo-5641937.jpeg?crop=entropy&cs=srgb&dl=pexels-anna-shvets-5641937.jpg&fit=crop&fm=jpg&h=960&w=640");
    }
    &.image-2 {
      background-image: url("https://unsplash.com/photos/hqCEQTc5gZA/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjM2OTg0MjM2&force=true&w=640");
//https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?crop=entropy&cs=srgb&dl=pexels-andrea-piacquadio-927022.jpg&fit=crop&fm=jpg&h=426&w=640");
      background-position: center center;
    }
  }
  .gradient{
  background: linear-gradient(to right, #d2cbc7, #817e85);;
}
.login-btn{
  background-color:#393949;
}
}
</style>